@import './config/styles/theme.less';

.navbarLogo {
  margin: 0px 8px 0px 0px !important;
}

.@{ant-prefix}-layout-sider-trigger {
  background-color: @--neutral-neutral-100 !important;
}

.@{ant-prefix}-layout-sider,
.@{ant-prefix}-menu.@{ant-prefix}-menu-dark {
  background-color: @--neutral-neutral-90 !important;
}

.triggerCloseButton {
  margin-top: 13px !important;
  margin-right: 13px !important;
}

.triggerExpandButton {
  margin-top: 13px !important;
}

.main-container {
  background-color: #e5e5e5 !important;
  overflow-y: auto;
  overflow-x: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.loader {
  border: 4px solid #dfe2e7;
  border-radius: 50%;
  border-top: 4px solid @--green-green-60;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.loader-wrapper {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 45%;
  z-index: 9999999;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  font-weight: bold;
  font-size: @--font-size-16;
  line-height: 22px;
  text-align: center;
  color: @--neutral-neutral-00;
  margin-bottom: 10px;
}

.@{ant-prefix}-layout.page-content {
  height: calc(100vh - 159px);
  margin: 22px;
}

.justify-content__space-between {
  justify-content: space-between;
  align-items: center;
}

.verticallyCenter {
  align-items: center;
  display: flex;
}

.horizantallyCenter {
  justify-content: center;
  display: flex;
}

.card-border {
  border: 1px solid @--neutral-neutral-20;
  border-radius: 8px;

  &:focus {
    border: blue;
  }
}

.navbarUserArea {
  padding-right: 0px !important;
  margin-top: 7px !important;
}

.syt-antd-popover-inner-content {
  padding: 5px 0px 20px 0px !important;
}

.syngenta-ant-menu-inline-collapsed {
  width: auto !important;
}

.syt-antd-avatar-circle {
  width: 40px;
  height: 50px;
  line-height: 40px;
  font-size: 18px;
  color: rgb(77, 81, 101);
  display: block;
  cursor: pointer;
  background: rgb(243, 244, 246);
  font-weight: 600;
  text-align: center;
}

.navbarDivider {
  display: none;
}

.field-label {
  color: @--neutral-neutral-70;
  font-size: @--font-size-16;
}

.field-value {
  color: @--neutral-neutral-70;
  font-size: @--font-size-16;
  font-weight: bold;
}


.s-cards {
  .syngenta-ant-card-bordered {
    border-radius: 10px;
    background-color: #fff;
  }

  .align-right {
    text-align: right;
  }

  .border {
    height: 1px;
    width: 75px;
    background: #dfe2e7;
    margin-top: 10px;
  }
}

.left-navigator__item-text {
  font-weight: 600;
  font-size: 14px;
}

.submenuName {
  margin-top: 5px;
}
@--neutral-neutral-00: #FFFFFF;@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: #C2C7D0;@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: #232630;@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--neutral-neutral-10: #F3F4F6;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--font-size-16: 16px;