.module-title {
    height: fit-content;
    background-color: @--neutral-neutral-00;
    font-size: 20px;
    line-height: 56px;
    border-bottom: 1px #dfe2e7;
    padding-left: 15px;
    color: #000 !important;
    font-weight: bold !important;
    min-height: 57px;
}

.ant-breadcrumb {
    height: fit-content;
    background-color: #ffffff;
    font-size: 20px;
    line-height: 56px;
    border-top: 1px solid #dfe2e7;
    border-bottom: 1px #dfe2e7;
    padding-left: 15px;
    color: #000 !important;
    font-weight: bold !important;

    li:first-child {
        cursor: pointer;
    }
}

.ant-breadcrumb .ant-breadcrumb-link a {
    color: #000 !important;
    font-weight: bold !important;
}

.ant-breadcrumb-separator:last-child {
    display: none;
}

.ant-breadcrumb-separator {
    color: black !important;
    font-size: 25px;
}

.ant-breadcrumb span.ant-breadcrumb-link,
.ant-breadcrumb span.ant-breadcrumb-separator {
    margin-left: 10px;

}

.ant-breadcrumb span:last-child {
    font-size: 24px;
}

.ant-breadcrumb span {
    text-transform: capitalize;
}
@--neutral-neutral-00: #FFFFFF;@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: #C2C7D0;@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: #232630;@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--neutral-neutral-10: #F3F4F6;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--font-size-16: 16px;