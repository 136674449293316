.syt-antd-menu-item svg {
  fill: #696f88;
}

.syt-antd-menu-item-selected svg {
  fill: #ffffff;
}

.syt-antd-menu-item-active svg {
  fill: #ffffff;
}

.@{ant-prefix}-layout-sider {
  height: calc(100vh - 56px)
}

.@{ant-prefix}-menu-dark.@{ant-prefix}-menu-dark:not(.@{ant-prefix}-menu-horizontal) .@{ant-prefix}-menu-item-selected {
  background-color: #171718 !important;

}

.@{ant-prefix}-menu-item-selected .sidebar-icon {
  filter: brightness(0) invert(1);
}

.@{ant-prefix}-menu-item {
  padding-left: 24px !important;
}

.@{ant-prefix}-menu-item-selected::before {
  content: " ";
  color: #ffffff;
  position: absolute;
  width: 4px;
  left: 0px;
  top: 4px;
  bottom: 4px;
  background: #ffffff;
  border-radius: 0px 4px 4px 0px;
}

.syt-antd-tooltip-inner {
  display: none;
}
@--neutral-neutral-00: #FFFFFF;@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: #C2C7D0;@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: #232630;@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--neutral-neutral-10: #F3F4F6;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--font-size-16: 16px;