.reports-card {
    display: flex;
    padding: 16px;
    width: 95%;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.report__title {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
}

.@{ant-prefix}-card-body {
    padding: 0;
    width: 100%;
}

.reports__submitted-assesment-icon {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid @--neutral-neutral-30;
    border-radius: 4px;
}

.reports__open-report {
    height: 40px;
    width: 100%;
    background: white;
    color: #14151c;
    border: 1px solid #c2c7d0;
    border-radius: 4px;
    align-content: space-around !important;
    margin-top: 16px;

    &:hover {
        cursor: pointer;
    }
}

.report__status-blue {
    background-color: blue;
    height: 10px;
    width: 10px;
    border-radius: 10px;
}

.report__status-green {
    background-color: green;
    height: 10px;
    width: 10px;
    border-radius: 10px;
}

.submit-assessment {
    .modal-title {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        margin-bottom: 20px;
    }

    table {
        .ant-table-column-sorters {
            justify-content: unset;
            gap: 10px;

            .ant-table-column-title {
                flex: unset;
            }
        }
    }
}

.reports__card-padding {
    padding: 8px 0px;
}

.report-title__row {
    margin-bottom: 16px;
}

.report-column {
    justify-content: flex-end;
    display: flex;
    height: 172px;
    margin-bottom: 24px;
}

#partner-name-block {
    width: 90%
}
@--neutral-neutral-00: #FFFFFF;@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: #C2C7D0;@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: #232630;@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--neutral-neutral-10: #F3F4F6;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--font-size-16: 16px;