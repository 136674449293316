@import "../../config/styles/theme.less";

.error-boundary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.error-boundary-label {
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 46px;
  color: rgb(46, 51, 125);
}

.main-layout {
  overflow-y: auto;

  #top-navigation-tabs {
    gap: 24px;
    overflow-y: auto;

    .layout-container {
      width: 25%;
    }
  }

  .layout-container {
    background: @--neutral-neutral-00;
    box-shadow: 0 2px 8px @--light-gray-15;
    border-radius: 10px !important;
    overflow: hidden;

    .card-wrapper {
      padding: 16px;
      overflow-y: auto;
      height: calc(100vh - 161px);

      .@{ant-prefix}-card {
        border: none;

        &:focus {
          background: @--blue-blue-10;

          .card-border {
            border: 2px solid @--blue-blue-20;
          }
        }

        .@{ant-prefix}-card-body {
          margin-top: 20px;
          padding: 0;

          .@{ant-prefix}-row {
            flex-wrap: nowrap;
            padding: 12px;
          }
        }

        &:nth-child(1) {
          .@{ant-prefix}-card-body {
            margin-top: 0;
          }
        }
      }
    }
    .card-wrapper:has(.left-navigator){
      height: calc(100vh - 334px);
    }
  }
}

.table-outer-border-only {
  .ant-table-container {
    border: 1px solid @--neutral-neutral-30 !important;
    border-radius: 8px;

    tr {
      th {
        background-color: @--neutral-neutral-10;
      }

      td {
        border: none;

        &.ant-table-cell-row-hover {
          background-color: transparent;
        }
      }
    }
  }
}

.table-bordered {
  .ant-table-container {
    border: 1px solid @--neutral-neutral-30 !important;
    border-radius: 8px;

    tr {
      th {
        background-color: @--neutral-neutral-10;
        border-right: 0.5px solid @--neutral-neutral-30;
        padding: 10px 8px;
        font-size: 14px;
      }

      td {
        border-width: 0.5px 0.5px 0 0 !important;
        border-color: @--neutral-neutral-30 !important;
        border-style: solid;
        padding: 10px 8px;
        font-size: 14px;
        font-weight: 500;

        &.ant-table-cell-row-hover {
          background-color: transparent;
        }

        &:last-child {
          border-right: none !important;
        }
      }
    }
  }
}

.ant-table-wrapper {
  margin-top: 10px;
}

.search-input {
  max-width: 587px;
  margin-bottom: 20px;

  .ant-input-wrapper {
    border: 1px solid @--neutral-neutral-30;
    border-radius: 4px;
    background-color: @--neutral-neutral-00;

    input {
      border: none;

      &:focus {
        box-shadow: none;
      }
    }

    .ant-input-group-addon {
      background: none;

      button {
        border: none;
        background: none;
      }

      button:focus-visible {
        outline: none;
      }
    }
  }
  svg{
    color: rgba(105, 111, 136, 1);
  }
  .suffix-icon{
    display: flex;
    gap: 7px;
    align-items: center;
  }
}

// 

.driver-table-main,
.outcomes-growers-container,
.sia-performance {
  .title {
    font-size: 16px;
    font-weight: 600;
  }
}

#top-navigation-tabs {
  gap: 24px;

  .layout-container {
    width: 25%
  }
}

.overview-context {
  div.ant-col {
    margin-top: 16px;
  }

  .ant-card-bordered {
    border: 1px solid @--neutral-neutral-30;
  }

  .overview-context-farms {
    h3 {
      color: @--neutral-neutral-100;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .soil-health{
    margin-bottom: 16px;

  }
}

.overview-context.water-quality{
  h3{
    height: 50px;
  }
}

.overview-context.soil-health{
  .ant-card,.ant-card-body{
    height: 100%;
  }
}

.outcomes-growers-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .outcomes-growers {
    background-color: @--neutral-neutral-00;
    box-shadow: 0 2px 8px 0 @--light-gray-15;
    border-radius: 12px;
    padding: 16px;
  }

  .outcomes-growes-chart {
    border: 1px solid @--neutral-neutral-30;
    border-radius: 10px;
    padding: 16px;
    height: 100%;
  }

  .outcomes-chart-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .outcomes-drivers-container {
    background-color: @--neutral-neutral-00;
    box-shadow: 0 2px 8px 0 @--light-gray-15;
    border-radius: 12px;
    padding: 16px;

    .growers-driver {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    .driver {
      .driver-chart-main {
        margin-bottom: 12px;
      }
    }

    canvas {
      width: 100% !important;
    }
  }
}

.sia-performance {
  .ant-card-bordered {
    border-radius: 8px;
    padding: 16px !important;
  }

  .ant-col,
  .ant-table-wrapper {
    margin: 0 !important;
  }

  canvas {
    height: 100% !important;
  }
}

.crop-production{
  .title{
    padding: 16px;
    border:  1px solid @--neutral-neutral-30;
    margin-top: 16px;
    border-radius: 12px 12px 0 0 ;
    border-bottom: 0;
    margin-bottom: 0;
  }
  .ant-table-wrapper{
    margin-top: 0 !important;
  }
  .ant-table-column-has-sorters{
    border-radius: 0 !important;
  }
  .ant-table-container{
    border-radius: 0;
  }
  .ant-table-container, tbody.ant-table-tbody > *{
    border-radius: 0 0 10px 10px !important;
  }
  .ant-table-placeholder{
    background-color: transparent !important;
  }
  .table-bordered .ant-table-container tr th:last-child{
    border-right: 0;
  }
}
.legend {
  display: grid;
  grid-template-columns: repeat(4,25%);
  li {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      margin-left: 4px;
      margin-right: 10px;
      text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    }
  }
}
.legend-color-box {
  width: 10px;
  min-width: 10px;
    height: 10px;
}
@--neutral-neutral-00: #FFFFFF;@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: #C2C7D0;@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: #232630;@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--neutral-neutral-10: #F3F4F6;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--font-size-16: 16px;