@import '../config/styles/theme.less';

.tab-container {
  display: flex;
  padding: 0 0 24px;
  height: calc(100vh - 314px);

  .reports-navigation-and-overview& {
    padding: 0px;
    height: calc(100vh - 161px);
  }

  .content-container {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .content-overflow-container {
      background-color: @--neutral-neutral-00;
      border-radius: 12px;
      padding: 16px;
      overflow-y: auto;
      height: 100%;
    }

    .content-main {
      flex: 1;
      min-height: 90%;
    }

    .btn-main {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      margin-top: 16px;

      button {
        padding: 10px 16px;
        height: auto;
        box-sizing: border-box;
        line-height: 20px !important;
        min-width: 85px;
        border-radius: 4px;

        &:hover {
          color: #000;
        }

        &.go-back {
          border: 1px solid @--neutral-neutral-30;
        }

        &.next-btn {
          background-color: @--green-green-60;
          color: @--neutral-neutral-00;
          display: flex;
          align-items: center;
        }
      }
    }

    .outcome-status {
      &-main {
        border: none;

        .ant-card-body {
          padding: 0;
        }
      }

      &-container {
        margin-bottom: 16px;
        padding: 16px;
        border: 1px solid @--neutral-neutral-30;
        border-radius: 8px;
      }

      &-item {
        display: flex;
        flex-direction: column;
        text-align: center;

        .font-16 {
          font-size: @--font-size-16;
          line-height: 24px;
        }

        .status {
          color: @--green-green-60;
          font-weight: 600;
          text-transform: capitalize;
          letter-spacing: -0.12px;
        }

        .name {
          color: @--light-black-85;
          font-weight: 400;
        }
      }
    }
  }
}

.navigator-title-section {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.container-title,
.driver-table-main,
.outcomes-growers-container,
.sai-performance {
  .title {
    font-size: @--font-size-16;
    font-weight: 700;
  }

}

.overview-context {
  div.ant-col {
    margin-top: 16px;
  }

  .ant-card-bordered {
    border: 1px solid @--neutral-neutral-30;
  }

  .overview-context-farms {
    h3 {
      color: @--neutral-neutral-100;
      font-size: @--font-size-16;
      font-weight: 600;
    }

    min-height: 250px;
  }

  &:last-child {
    >div>div {

      .table-main,
      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table,
      .ant-table-container,
      .ant-table-content {
        height: 100%;
      }
    }
  }
}

.overview-context.context {

  .ant-card-body,
  .ant-card {
    height: 100%;
  }
}

.outcomes-growers-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .outcomes-growers {
    background-color: @--neutral-neutral-00;
    box-shadow: 0 2px 8px 0 @--light-gray-15;
    border-radius: 12px;
    padding: 16px;
  }

  .outcomes-growes-chart {
    border: 1px solid @--neutral-neutral-30;
    border-radius: 10px;
    padding: 16px;
    height: 100%;
  }

  .outcomes-chart-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .outcomes-drivers-container {
    background-color: @--neutral-neutral-00;
    box-shadow: 0 2px 8px 0 @--light-gray-15;
    border-radius: 12px;
    padding: 16px;

    .driver {
      .driver-chart-main {
        margin-bottom: 12px;
      }
    }

    canvas {
      width: 100% !important;
    }
  }
}

.sai-performance {
  .ant-card-bordered {
    border-radius: 8px;
    padding: 16px !important;
  }

  .ant-col,
  .ant-table-wrapper {
    margin: 0 !important;
  }

  canvas {
    height: 100% !important;
  }
}

.crop-production {
  .title {
    padding: 16px;
    border: 1px solid @--neutral-neutral-30;
    margin-top: 16px;
    border-radius: 12px 12px 0 0;
    border-bottom: 0;
    margin-bottom: 0;
  }

  .ant-table-wrapper {
    margin-top: 0 !important;
  }

  .ant-table-column-has-sorters {
    border-radius: 0 !important;
  }

  .ant-table-container {
    border-radius: 0;
  }

  .ant-table-container,
  tbody.ant-table-tbody>* {
    border-radius: 0 0 10px 10px !important;
  }

  .ant-table-placeholder {
    background-color: transparent !important;
  }

  .table-bordered .ant-table-container tr th:last-child {
    border-right: 0;
  }
}
@--neutral-neutral-00: #FFFFFF;@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: #C2C7D0;@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: #232630;@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--neutral-neutral-10: #F3F4F6;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--font-size-16: 16px;