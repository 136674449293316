.logoutOverlayBlock {
  width: 320px;
  min-height: 120px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.profileInfo {
  padding: 15px 15px;
  border-bottom: 1px solid #dfe2e7;
}
.profileInfo h3 {
  font-weight: 600;
  color: #14151c;
}
.profileUserData {
  margin-top: 10px;
  margin-bottom: 10px;
}
.profileUser {
  width: 24px;
  height: 24px;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  text-align: center;
  color: #4d5165;
  border: 1px solid #e8eaed;
  border-radius: 50%;
  margin-right: 10px;
  padding: 3px 3px;
  font-weight: 600;
  background: #f2f4f6;
  float: left;
}
.profileUserData span {
  color: #14151c;
}
.logoutLink {
  padding: 12px 15px 15px 15px;
}
.logoutLink span {
  color: #cf3537;
  cursor: pointer;
}
.logoutLink span:hover {
  color: #ee3f42;
}

.profileInfo .@{ant-prefix}-radio-button-wrapper {
  cursor: pointer;
}
.syt-antd-dropdown-hidden {
  display: none;
}

@--neutral-neutral-00: #FFFFFF;@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: #C2C7D0;@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: #232630;@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--neutral-neutral-10: #F3F4F6;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--font-size-16: 16px;