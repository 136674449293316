.config-message {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
  color: aliceblue;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@--neutral-neutral-00: #FFFFFF;@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: #C2C7D0;@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: #232630;@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--neutral-neutral-10: #F3F4F6;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--font-size-16: 16px;