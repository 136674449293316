@import '../../config/styles/theme.less';

.top-tab-box {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  background: @--neutral-neutral-00;
  margin-bottom: 24px;
  box-shadow: 0 2px 8px 0 @--light-gray-15;

  .tab-title {
    font-size: @--font-size-16;
    font-weight: 600;
  }

  .tab-section {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .tab-main {
      display: flex;
      padding: 12px;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid @--neutral-neutral-20;
      background: @--neutral-neutral-00;

      &.active {
        background-color: @--blue-blue-60;
        border: 2px solid @--blue-blue-20;

        span {
          color: @--neutral-neutral-00;
        }
      }
    }
  }
}

.icon-background {
  border-radius: 32px;
  background: @--neutral-neutral-10;
  display: flex;
  padding: 4px;
  height: 25px;
  width: 25px;

  img {
    width: 18px;
  }

  &.active {
    background-color: @--neutral-neutral-00
  }
}

.top-tab-box .tab-section .tab:focus {
  background: @--blue-blue-60;
}

.left-navigator-card {
  cursor: pointer;

  &.active {
    background-color: @--blue-blue-10;
    border: 1px solid @--blue-blue-50;
  }
}

.outcome-title {
  margin-bottom: 25px;
}
@--neutral-neutral-00: #FFFFFF;@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: #C2C7D0;@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: #232630;@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--neutral-neutral-10: #F3F4F6;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--font-size-16: 16px;